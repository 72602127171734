.footer {
  margin-top: auto;
  text-align: center;
  padding: 10px;
  font-size: 18px;
}

.footer .logo-container {
  font-size: 30px;
}

.footer .avocado-icon {
  width: 22px;
}

@media screen and (max-width: 1200px) {
  .footer {
    font-size: max(1.5vw, 10px);
  }

  .footer .logo-container {
    font-size: max(2vw, 15px);
  }

  .footer .avocado-icon {
    width: max(1.6vw, 12px);
  }
}
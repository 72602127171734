.home-page .main-image-container {
  width: 100%;
  position: relative;
}

.home-page .main-image-container .rome-image {
  width: 100%;
}

.home-page .main-image-container .image-mask {
  display: none;
}

.home-page .main-image-container .image-text-container {
  position: absolute;
  bottom: 40px;
  max-width: 30%;
  padding: 10px 30px;
  border-radius: 0 20px 20px 0;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  color: white;
  cursor: pointer;
  box-sizing: border-box;
}

.home-page .main-image-container .image-text-container:hover {
  transform: scale(1.02);
}

.home-page .main-image-container .image-text-container .mission-header {
  font-size: 60px;
  font-weight: normal;
}

.home-page .main-image-container .image-text-container .mission-text {
  font-size: 18px;
}


.home-page .section-header {
  font-size: 40px;
  font-weight: bold;
  margin: 15px 30px;
}

.home-page .recipes-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 0px 10px;
}

.home-page .recipes-container .recipe-preview {
  width: 20%;
  min-width: 300px;
  transition: 0.1s;
  border-radius: 10px;
  cursor: pointer;
  margin: 0px 10px 20px 10px;
}

.home-page .recipes-container .recipe-preview:hover {
  box-shadow: 0px 0px 10px 2px grey;
}

.home-page .recipes-container .recipe-preview .recipe-image {
  border-radius: 10px;
  width: 100%;
}

.home-page .recipes-container .recipe-preview:hover .recipe-image,
.home-page .recipes-container .recipe-preview .recipe-image:hover {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  transition: 0.1s;
}

.home-page .recipes-container .recipe-text {
  text-align: center;
  padding-top: 5px;
  padding-bottom: 10px;
  font-size: 18px;
}


@media screen and (min-width: 600px) and (max-width: 1200px) {
  .home-page .main-image-container .image-text-container {
    max-width: 40%;
  }

  .home-page .main-image-container .image-text-container .mission-header {
    font-size: max(5vw, 18px);
  }

  .home-page .main-image-container .image-text-container .mission-text {
    font-size: max(1.5vw, 14px);
  }

  .home-page .section-header {
    font-size: max(3.5vw, 18px);
  }

  .home-page .recipes-container .recipe-text {
    font-size: max(1.5vw, 14px);
  }
}

@media screen and (max-width: 600px) {
  .home-page .main-image-container .rome-image {
    height: 400px;
    object-fit: cover;
    object-position: bottom;
  }

  .home-page .main-image-container .image-mask {
    display: block;
    position: absolute;
    top: 0px;
    height: 400px;
    width: 100%;
    mask-image: linear-gradient(to bottom, transparent 70%, black 140%);
    background-color: black;
  }

  .home-page .main-image-container .image-text-container {
    bottom: 4px;
    max-width: 100%;
    width: 100%;
    border-radius: 0;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    text-shadow: 0px 0px 10px black;
  }

  .home-page .main-image-container .image-text-container .mission-header {
    font-size: 50px
  }

  .home-page .main-image-container .image-text-container .mission-text {
    font-size: 16px
  }
}

.recipe {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recipe .recipe-image {
  width: 60%;
  border-radius: 10px;
}

.recipe .recipe-card {
  margin-top: 20px;
  padding: 20px;
  box-sizing: border-box;
  background-color: rgb(228, 226, 224);
  max-width: 750px;
}

.recipe .recipe-card .material-symbols-outlined {
  color: rgb(20, 113, 123);
}

.recipe .recipe-name {
  font-family: 'Montserrat', sans-serif;
  ;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.recipe .info-container {
  display: flex;
  align-items: center;
  padding: 10px 0px;
}

.recipe .info-container .time-item {
  padding: 0px 10px
}

.recipe .info-container .time-item .time-title {
  font-weight: bold;
}

.recipe .info-container .servings-title {
  font-weight: bold;
  padding: 0px 10px;
}

.recipe .recipe-description-container .description-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 1px solid black;
  margin-bottom: 10px;
  padding-bottom: 5px;
  margin-top: 20px;
}


.recipe .recipe-description-container .description-sub-title {
  font-family: 'Montserrat', sans-serif;
  ;
  margin-left: 10px;
  font-size: 18px;
  font-weight: bold;
}

.recipe .recipe-description-container .description-text {
  font-size: 18px;
}

.recipe .recipe-description-container .ingredient-list {
  margin-top: 5px;
}

.recipe .recipe-description-container li {
  padding: 5px 0px;
}

.recipe .recipe-description-container .instruction::marker {
  font-weight: bold;
}

.recipe .recipe-description-container .recipe-link {
  color: rgb(33, 130, 140);
  font-weight: 600;
}

.recipe .recipe-description-container .nutrition-text {
  font-size: 15px;
}

.recipe .recipe-description-container .nutrition-text .n-type {
  font-weight: bold;
}

.recipe .recipe-description-container .multiplier {
  border: 1px solid black;
  float: right;
  margin: 0px 5px;
  width: 30px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 25px;
}

.recipe .recipe-description-container .multiplier.selected {
  background-color: black;
  color: rgb(228, 226, 224);
}

@media screen and (max-width: 800px) {
  .recipe .recipe-image {
    width: 80%;
  }
}

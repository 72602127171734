.app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  font-family: "Quicksand", sans-serif;
  background-color: rgb(245, 242, 240);
  color: rgb(33, 37, 41);
}

.app .title-text {
  font-family: "Amatic SC", sans-serif;
  letter-spacing: .3pt;
  font-weight: bold;
}

.app .button {
  cursor: pointer;
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.error-container .error-title {
  font-size: 40px;
  font-weight: bold;
}

.error-container .error-text {
  font-size: 20px;
}

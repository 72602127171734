.contact {
  display: flex;
  justify-content: center;
  gap: 40px;
  padding: 20px;
  margin-top: 20px;
}

.contact .contact-image {
  width: 300px;
  align-self: baseline;
}

.contact .contact-text-container {
  width: 500px;
}

.contact .contact-text-container .contact-title {
  font-size: 40px;
}

.contact .contact-text-container .contact-email {
  font-size: 14px;
  margin: 10px 0px;
}

.contact .contact-text-container .input {
  width: 100%;
  margin: 10px 0px;
}

.contact .send-button {
  margin-top: 5px;
}

.contact .snackbar .snackbar-content {
  display: flex;
  gap: 10px;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .contact {
    margin-top: 0px;
  }

  .contact .contact-image {
    display: none;
  }
}

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0px
}

.about .profile {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
  margin: 30px 0px
}

.about .profile .profile-image {
  height: 300px;
  border-radius: 50%;
  align-self: center;
}

.about .profile .profile-text-container {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about .profile .profile-text-container .profile-title {
  margin-bottom: 10px;
  font-size: 35px;
}

.about .profile .profile-text-container .profile-text {
  font-size: 16px;
}

.about .team-container {
  padding: 0px 20px;
}

.about .team-container .team-title {
  font-size: 28px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.about .team-container .team-member {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
}

.about .team-container .team-member .profile-image {
  width: 125px;
  border-radius: 50%;
}

.about .team-container .team-member .member-name {
  font-size: 16px;
}

.about .team-container .team-member .member-description {
  font-size: 12px;
}

@media screen and (min-width: 800px) and (max-width: 1200px) {
  .about .profile .profile-text-container {
    width: 50%;
  }
}

@media screen and (max-width: 800px) {
  .about {
    margin-top: 0px;
  }

  .about .profile {
    gap: 30px;
  }

  .about .profile .profile-text-container {
    width: 80%;
  }
}
.shop {
  background-color: white;
}

.shop .shop-header {
  height: 300px;
}

.shop .shop-header .shop-header-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: center;
}

.shop .title-mask {
  width: 100%;
  height: 196px;
  position: relative;
  top: -200px;
  mask-image: linear-gradient(to bottom, transparent 0%, black 180%);
  background-color: black;
}

.shop .shop-title {
  position: absolute;
  top: 220px;
  width: 100%;
  font-size: 100px;
  text-align: center;
  letter-spacing: 3pt;
  color: white;
}

.shop .search-input {
  width: 300px;
  margin-left: calc(50% - 150px);
  margin-top: 20px;
}

.shop .chip-container {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}

.shop .chip-container .tab-chip {
  width: 80px;
  margin: 0px 20px;
}

.shop .shop-sub-title {
  text-align: center;
  font-size: 40px;
  margin: 10px;
}

.shop .shop-description {
  text-align: center;
  font-size: 16px;
  padding: 0px 10px;
}

.shop .border {
  border-bottom: 1px solid rgb(20, 113, 123);
  margin: 20px 10% 0px 10%;
}

.shop .shop-item-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px;
}

.shop .shop-item-container .shop-item {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  margin: 20px 10px;
}

.shop .shop-item-container .shop-item .item-image {
  max-width: 200px;
  max-height: 200px;
}

.shop .shop-item-container .shop-item .item-text {
  color: black;
  font-weight: 500;
  text-align: center;
}

@media screen and (max-width: 800px) {
  .shop .shop-item-container .shop-item {
    width: 150px;
  }

  .shop .shop-item-container .shop-item .item-image {
    max-width: 100px;
    max-height: 100px;
  }
}
.header {
  width: 100%;
  min-height: 68px;
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  font-weight: bold;
  letter-spacing: 3px;
}

.header .search-icon {
  font-size: 20px;
  position: absolute;
  left: 30px;
  cursor: pointer;
}

.header .search-icon:hover {
  color: #50684B
}

.header .search-input {
  position: absolute;
  left: 60px;
}

.header .back-icon {
  position: absolute;
  left: 30px;
  cursor: pointer;
}

.header .back-icon:hover {
  color: #50684B
}

.header .avocado-icon {
  width: 45px;
  padding: 0px 10px;
}

.header .menu-container {
  position: absolute;
  right: 30px;
}

.header .menu-container .menu-link {
  float: right;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: normal;
  cursor: pointer;
  margin-left: 20px;
}

.header .menu-container .menu-link:hover {
  color: #50684B
}

.header .menu-container .menu-link.material-symbols-outlined {
  font-size: 22px;
  font-weight: normal;
}

.header .menu-icon {
  position: absolute;
  right: 30px;
  padding: 0px;
  color: black;
}

.header .menu-icon:hover {
  color: #50684B
}

.header .title {
  cursor: pointer;
}

.header .title:hover {
  color: #50684B
}

.dropdown-menu .menu-item {
  width: 100px;
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .header {
    font-size: max(5vw, 40px);
  }

  .header .search-icon {
    font-size: max(1.7vw, 20px);
  }

  .header .avocado-icon {
    width: max(4vw, 30px);
  }
}
